<template>
  <div class="animated fadeIn">
    <b-form @submit.prevent="onUpdate" novalidate>
      <!-- Customer Information -->
      <b-card-header>
        Customer Information
        <div class="card-header-actions">
          <a
            class="card-header-action"
            href="verify-partner"
            rel="noreferrer noopener"
          >
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card>
        <b-row class="mb-4">
          <b-col class="mb-4" sm="6">
            <h6>NIK</h6>
            <b-input-group>
              <b-form-input
                id="nik"
                type="text"
                v-model="form.nik"
                :state="chkState('nik')"
                @input="$v.form.nik.$touch()"
              />
              <b-form-invalid-feedback id="nikFeedback">
                <span v-if="!$v.form.nik.required"
                  >- NIK tidak boleh kosong!
                </span>
                <span v-if="!$v.form.nik.numeric"
                  >- NIK harus angka valid!
                </span>
              </b-form-invalid-feedback>
            </b-input-group>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Email</h6>
            <b-input-group>
              <b-form-input
                id="email"
                type="text"
                :value="parseValue(data.email)"
                disabled
              />
            </b-input-group>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Name</h6>
            <b-form-input
              id="name"
              type="text"
              size="md"
              v-model="form.name"
              aria-describedby="reasonFeedback"
              placeholder="Masukan Nama"
              autocomplete="given-name"
              autofocus
              :state="chkState('name')"
              @input="$v.form.name.$touch()"
            ></b-form-input>
            <b-form-invalid-feedback id="nameFeedback">
              <span v-if="!$v.form.name.required"
                >- Nama tidak boleh kosong!
              </span>
            </b-form-invalid-feedback>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>No Handphone</h6>
            <b-input-group>
              <b-form-input
                id="phone"
                type="text"
                :value="parseValue(data.phone)"
                disabled
              />
            </b-input-group>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Tanggal Lahir</h6>
            <b-form-group class="mb-0" id="date" label-for="date">
              <date-picker
                v-model="form.birthday"
                format="DD-MM-YYYY"
                type="date"
                lang="en"
                value-type="format"
                placeholder="DD-MM-YYYY"
                style="width: 100%;"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Partner</h6>
            <b-input-group>
              <b-form-input
                id="partner"
                type="text"
                :value="
                  parseValue(
                    this.data.partner
                      ? this.data.partner
                      : this.data.organization
                      ? this.data.organization
                      : null
                  )
                "
                disabled
              />
            </b-input-group>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Jenis Kelamin:</h6>
            <b-form-group class="mb-0" id="gender" label-for="idcard">
              <b-form-select
                v-model="form.gender"
                :options="[
                  { text: 'Select Gender', value: '' },
                  { text: 'Perempuan', value: 'female' },
                  { text: 'Laki-Laki', value: 'male' }
                ]"
                :state="chkState('gender')"
                @input="$v.form.gender.$touch()"
              ></b-form-select>
              <b-form-invalid-feedback id="nikFeedback">
                <span v-if="!$v.form.gender.required"
                  >- Jenis Kelamin tidak boleh kosong!
                </span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <!-- Address Information -->
      <b-card-header>
        Address According ID Card
      </b-card-header>
      <b-card>
        <b-row class="mb-4">
          <b-col class="mb-4" sm="6">
            <h6>Alamat</h6>
            <b-form-input
              id="address"
              type="text"
              size="md"
              v-model="form.address"
              aria-describedby="reasonFeedback"
              placeholder="Masukan Alamat"
              autocomplete="given-name"
              autofocus
              :state="chkState('address')"
              @input="$v.form.address.$touch()"
            ></b-form-input>
            <b-form-invalid-feedback id="addressFeedback">
              <span v-if="!$v.form.address.required"
                >- Alamat tidak boleh kosong!
              </span>
            </b-form-invalid-feedback>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Kecamatan</h6>
            <v-select
              id="district"
              v-model="form.district"
              placeholder="Select Kecamatan"
              aria-describedby="districtFeedback"
              :options="districtOption"
              :on-change="checkDistrict"
              style="padding-top: 0;"
              :state="chkState('district')"
              @change="$v.form.district.$touch()"
              :class="!$v.form.district.required ? 'error-input' : ''"
            ></v-select>
            <span v-if="!$v.form.district.required" class="error-message"
              >- Kecamatan tidak boleh kosong!
            </span>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Provinsi</h6>
            <v-select
              id="province"
              v-model="form.province"
              placeholder="Select Provinsi"
              aria-describedby="provinceFeedback"
              :options="provinceOption"
              :on-change="checkProvince"
              style="padding-top: 0;"
              :state="chkState('province')"
              @change="$v.form.province.$touch()"
              :class="!$v.form.province.required ? 'error-input' : ''"
            ></v-select>
            <span v-if="!$v.form.province.required" class="error-message"
              >- Provinsi tidak boleh kosong!
            </span>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Kelurahan <span class="text-muted">(opsional)</span></h6>
            <v-select
              id="village"
              v-model="form.village"
              placeholder="Select Kelurahan"
              aria-describedby="villageFeedback"
              :options="villageOption"
              :on-change="fillPostalCode"
              style="padding-top: 0;"
            ></v-select>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Kota / Kabupaten</h6>
            <v-select
              id="city"
              v-model="form.city"
              placeholder="Select Kota"
              aria-describedby="cityFeedback"
              :options="cityOption"
              :on-change="checkCity"
              style="padding-top: 0;"
              :state="chkState('city')"
              @change="$v.form.city.$touch()"
              :class="!$v.form.city.required ? 'error-input' : ''"
            ></v-select>
            <span v-if="!$v.form.city.required" class="error-message"
              >- Kota tidak boleh kosong!
            </span>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Kode POS <span class="text-muted">(opsional)</span></h6>
            <b-form-input
              id="postal_code"
              type="number"
              size="md"
              v-model="form.postalCode"
              aria-describedby="postalCodeFeedback"
              placeholder="Masukan Kode POS"
              autocomplete="given-name"
              autofocus
            ></b-form-input>
          </b-col>
        </b-row>
      </b-card>
      <!-- KYC Attempt -->
      <b-card-header>
        KYC Attempt
      </b-card-header>
      <b-card>
        <b-row class="mb-4">
          <b-col sm="3">
            <h5>ID Forgery</h5>
            <p>
              <strong>{{ this.data.attempt.id_forgery }} Attempt</strong>
            </p>
          </b-col>
          <b-col sm="3">
            <h5>Photo Selfie</h5>
            <p>
              <strong>{{ this.data.attempt.selfie }} Attempt</strong>
            </p>
          </b-col>
          <b-col sm="3">
            <h5>Face Compare</h5>
            <p>
              <strong>{{ this.data.attempt.face_compare }} Attempt</strong>
            </p>
          </b-col>
          <b-col sm="3">
            <h5>Dukcapil Verification</h5>
            <p>
              <strong>{{ this.data.attempt.smma }} Attempt</strong>
            </p>
          </b-col>
        </b-row>
      </b-card>
      <!-- KYC Information -->
      <b-card-header>
        KYC Information
      </b-card-header>
      <b-card>
        <b-row class="mb-4">
          <b-col sm="6">
            <!-- KTP Status -->
            <div class="mb-4">
              <b-form-group label="KTP Status">
                <b-form-radio-group
                  id="ktp--radio"
                  v-model="form.ktpStatus"
                  @change="radioChange('status-ktp')"
                  name="ktp--radio"
                >
                  <b-form-radio :value="0">Not Verified</b-form-radio>
                  <b-form-radio :value="1">Verified</b-form-radio>
                  <b-form-radio :value="2">Rejected</b-form-radio>
                  <b-form-radio :value="3">On Process</b-form-radio>
                  <b-form-radio :value="4">Submitted</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group
                id="ktpReason"
                label-for="reason-ktp"
                v-show="form.ktpStatus === 2"
              >
                <v-select
                  id="reason-ktp"
                  v-model="form.reasonKtp"
                  placeholder="Reason KTP"
                  aria-describedby="reasonKtp"
                  :options="reasonOption"
                  style="padding-top: 0;"
                  :state="chkState('reasonKtp')"
                  @change="$v.form.reasonKtp.$touch()"
                  :class="!$v.form.reasonKtp.required ? 'error-input' : ''"
                ></v-select>
              </b-form-group>
              <span
                v-if="!$v.form.reasonKtp.required && form.ktpStatus === 2"
                class="error-message"
                >- Reason KTP tidak boleh kosong!
              </span>
            </div>
            <!-- Forgery Status -->
            <div class="mb-4">
              <h6>Forgery Status</h6>
              <p
                class="text-red"
                v-if="
                  data.forgery_status &&
                    data.forgery_status.includes('Rejected')
                "
              >
                <strong>{{ this.data.forgery_status }}</strong>
              </p>
              <p
                class="text-success"
                v-else-if="
                  data.forgery_status &&
                    data.forgery_status.includes('Verified')
                "
              >
                <strong>{{ this.data.forgery_status }}</strong>
              </p>
              <p v-else>
                <strong>{{ this.data.forgery_status }}</strong>
              </p>
            </div>
            <!-- Upload KTP -->
            <div class="mb-4">
              <h6>Upload Foto KTP</h6>
              <div class="upload-images-kyc">
                <b-form-file
                  class="mb-4"
                  v-model="form.fileKtp"
                  ref="file-ktp"
                  accept=".jpeg, .jpg, .png"
                  @change="checkExtension($event, 'file-ktp')"
                  plain
                >
                </b-form-file>
                <a
                  v-if="form.imageKtp"
                  :href="form.imageKtp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img :src="form.imageKtp" alt="Image KTP" />
                </a>
              </div>
            </div>
            <!-- Verifikasi -->
            <div class="mb-4">
              <h6>Diverifikasi Oleh</h6>
              <p>
                <strong>{{ this.data.action_by }}</strong>
              </p>
            </div>
          </b-col>
          <b-col sm="6">
            <!-- Selfie Status -->
            <div class="mb-4">
              <b-form-group label="Selfie Status">
                <b-form-radio-group
                  id="selfie--radio"
                  v-model="form.selfieStatus"
                  @change="radioChange('status-selfie')"
                  name="selfie--radio"
                >
                  <b-form-radio :value="0">Not Verified</b-form-radio>
                  <b-form-radio :value="1">Verified</b-form-radio>
                  <b-form-radio :value="2">Rejected</b-form-radio>
                  <b-form-radio :value="3">On Process</b-form-radio>
                  <b-form-radio :value="4">Submitted</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group
                id="ktpReason"
                label-for="reason-selfie"
                v-show="form.selfieStatus === 2"
              >
                <v-select
                  id="reason-selfie"
                  v-model="form.reasonSelfie"
                  placeholder="Reason Selfie"
                  aria-describedby="reasonSelfie"
                  :options="reasonOption"
                  style="padding-top: 0;"
                  :state="chkState('reasonSelfie')"
                  @change="$v.form.reasonSelfie.$touch()"
                  :class="!$v.form.reasonSelfie.required ? 'error-input' : ''"
                ></v-select>
              </b-form-group>
              <span
                v-if="!$v.form.reasonSelfie.required && form.selfieStatus === 2"
                class="error-message"
                >- Reason Selfie tidak boleh kosong!
              </span>
            </div>
            <!-- Selfie Presentase -->
            <div class="mb-4">
              <div class="d-flex align-items-center justify-content-between">
                <h6>Persentase Foto Selfie</h6>
                <h6>{{ `${this.data.liveness_detection}%` }}</h6>
              </div>
              <b-progress
                :value="this.data.liveness_detection"
                :max="100"
              ></b-progress>
            </div>
            <!-- Selfie & KTP Presentase -->
            <div class="mb-4">
              <div class="d-flex align-items-center justify-content-between">
                <h6>Persentase Kecocokan Foto KTP dan Foto Selfie</h6>
                <h6>{{ `${this.data.compare_percentage}%` }}</h6>
              </div>
              <b-progress
                :value="this.data.compare_percentage"
                :max="100"
              ></b-progress>
            </div>
            <!-- Upload Selfie -->
            <div class="mb-4">
              <h6>Upload Foto Selfie</h6>
              <div class="upload-images-kyc">
                <b-form-file
                  class="mb-4"
                  v-model="form.fileSelfie"
                  ref="file-selfie"
                  accept=".jpeg, .jpg, .png"
                  @change="checkExtension($event, 'file-selfie')"
                  plain
                >
                </b-form-file>
                <a
                  v-if="form.imageSelfie"
                  :href="form.imageSelfie"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img :src="form.imageSelfie" alt="Image Selfie" />
                </a>
              </div>
            </div>
            <!-- Verifikasi -->
            <div class="mb-4">
              <h6>Diverifikasi Oleh</h6>
              <p>
                <strong>{{ this.data.action_by }}</strong>
              </p>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card-body class="mt-0 d-flex  mb-5 pt-0 pl-0" style="gap:8px">
        <!-- <b-button  variant="success">
          Cek Data
        </b-button> -->
        <b-overlay
          :show="busy"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-dropdown
            id="option-cek-data"
            class="mx-1"
            variant="success"
            left
            text="Cek Data"
          >
            <b-dropdown-item @click="verifyKYC('ocr')" :disabled="btnKycKtp"
              >Proses OCR</b-dropdown-item
            >
            <b-dropdown-item @click="verifyKYC('forgery')" :disabled="btnKycKtp"
              >Cek Forgery</b-dropdown-item
            >
            <b-dropdown-item
              @click="verifyKYC('faceCompare')"
              :disabled="btnKycSelfie"
              >Cek Face Compare</b-dropdown-item
            >
            <b-dropdown-item
              @click="verifyKYC('dukcapil')"
              :disabled="btnKycKtp"
              >Cek Dukcapil</b-dropdown-item
            >
          </b-dropdown>
        </b-overlay>
        <b-overlay
          :show="loadingSubmit"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button type="submit" variant="primary">
            Submit
          </b-button>
        </b-overlay>
      </b-card-body>
    </b-form>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required, numeric } from "vuelidate/lib/validators";

export default {
  name: "detail-verifikasi",
  components: {},
  mixins: [validationMixin],
  validations: {
    form: {
      nik: {
        required,
        numeric
      },
      name: {
        required
      },
      gender: {
        required
      },
      address: {
        required
      },
      province: {
        required
      },
      district: {
        required
      },
      city: {
        required
      },
      reasonKtp: {
        required
      },
      reasonSelfie: {
        required
      }
    }
  },
  data() {
    return {
      data: {},
      form: {
        name: "",
        birthday: "",
        gender: "",
        idNumber: "",
        ktpStatus: "",
        selfieStatus: "",
        reasonKtp: "",
        reasonSelfie: "",
        fileKtp: null,
        fileSelfie: null,
        imageKtp: null,
        imageSelfie: null,
        address: "",
        district: "",
        districtId: "",
        province: "",
        provinceId: "",
        village: "",
        villageId: "",
        city: "",
        cityId: "",
        postalCode: "",
        nik: ""
      },
      isLoading: false,
      loadingSubmit: false,
      provinceData: [],
      provinceOption: [],
      cityData: [],
      cityOption: [],
      districtData: [],
      districtOption: [],
      villageData: [],
      villageOption: [],
      warning_message: "",
      warning_message_ktp: "",
      warning_message_selfie: "",
      errors: {
        code: "",
        message: "",
        status: ""
      },
      reasonOption: [
        {
          label: "RC-01 - Invalid ID Card Photo",
          id: "RC-01 - Invalid ID Card Photo"
        },
        {
          label: "RC-02 - No Similiarity between ID Card Photo and Selfie",
          id: "RC-02 - No Similiarity between ID Card Photo and Selfie"
        },
        {
          label: "RC-03 - Regulatory Records Unavailable",
          id: "RC-03 - Regulatory Records Unavailable"
        },
        {
          label: "RC-04 - Regulatory Compilance Issue",
          id: "RC-04 - Regulatory Compilance Issue"
        },
        {
          label: "RC-05 - Regulatory Constraints",
          id: "RC-05 - Regulatory Constraints"
        },
        {
          label: "RC-06 - Invalid Data",
          id: "RC-06 - Invalid Data"
        }
      ],
      busy: false,
      btnKycKtp: false,
      btnKycSelfie: false
    };
  },
  created() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
    this.onFetchDetail();
  },
  computed: {
    formStr() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
    invCheck() {
      return "You must accept before submitting";
    }
  },
  watch: {
    provinceData() {
      if (
        this.form.province &&
        !this.form.provinceId &&
        this.provinceData.length > 0
      )
        this.form.provinceId = this.getIdValue(
          this.form.province,
          this.provinceData
        );
    },
    cityData() {
      if (this.form.city && !this.form.cityId && this.cityData.length > 0)
        this.form.cityId = this.getIdValue(this.form.city, this.cityData);
    },
    districtData() {
      if (
        this.form.district &&
        !this.form.districtId &&
        this.districtData.length > 0
      )
        this.form.districtId = this.getIdValue(
          this.form.district,
          this.districtData
        );
    },
    villageData() {
      if (
        this.form.village &&
        !this.form.villageId &&
        this.villageData.length > 0
      )
        this.form.villageId = this.getIdValue(
          this.form.village,
          this.villageData
        );
    },
    "form.reasonKtp": {
      handler(newReasonKtp) {
        if (typeof newReasonKtp === "string")
          this.form.reasonKtp = this.getIdValue(
            this.form.reasonKtp,
            this.reasonOption,
            "reason"
          );
      }
    },
    "form.reasonSelfie": {
      handler(newreasonSelfie) {
        if (typeof newreasonSelfie === "string")
          this.form.reasonSelfie = this.getIdValue(
            this.form.reasonSelfie,
            this.reasonOption,
            "reason"
          );
      }
    }
  },
  methods: {
    getIdValue(val, data, type) {
      if (val && data.length > 0) {
        const find = data.filter(v => {
          return type === "reason" ? v.id == val : v.text === val;
        });

        if (find.length > 0) return type === "reason" ? find[0] : find[0].value;
      } else {
        return null;
      }
    },
    radioChange(val) {
      if (val === "status-ktp" && this.form.ktpStatus !== 2) {
        this.form.reasonKtp = "";
        if (this.form.ktpStatus === 1) {
          this.btnKycKtp = true;
        } else {
          this.btnKycKtp = false;
        }
      } else if (val === "status-selfie" && this.form.selfieStatus !== 2) {
        this.form.reasonSelfie = "";
        if (this.form.selfieStatus === 1) {
          this.btnKycSelfie = true;
        } else {
          this.btnKycSelfie = false;
        }
      }
    },
    isUnique(value) {
      if (value === "") return true;

      return new Promise((resolve, reject) => {
        this.$http
          .post(`/verify-check/${value}`)
          .then(res => {
            if (res.data.data.exists === true) {
              this.warning_message =
                "Nomor KTP sudah terpakai. Silakan cek kembali. Kamu tetap bisa submit verifikasi.";
            } else {
              this.warning_message = "";
            }
            resolve(res);
          })
          .catch(error => {
            if (error.response) {
              reject(error);
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
            }
          });
      });
    },
    parseValue(event) {
      return event ? event : "-";
    },
    checkExtension(value, type) {
      const validExts = new Array(".jpeg", ".jpg", ".png");
      const maxFileSize = 1024 * 1024; // 1MB
      const files = value.target.files[0];
      const fileExt = files.name.substring(files.name.lastIndexOf("."));
      let errorMessage = "";

      if (!validExts.includes(fileExt)) {
        errorMessage = `Invalid file selected, valid files are of ${validExts.toString()} types.`;
      } else if (files.size > maxFileSize) {
        errorMessage = "File size exceeds the 1MB limit.";
      } else if (!validExts.includes(fileExt) && files.size > maxFileSize) {
        errorMessage = `Invalid file selected, valid files are of ${validExts.toString()} types, and file size exceeds the 1MB limit.`;
      }

      if (!validExts.includes(fileExt) || files.size > maxFileSize) {
        this.$swal.fire("Failed!", errorMessage, "error").then(() => {
          return false;
        });

        return false;
      } else {
        if (type === "file-ktp") {
          this.form.fileKtp = files;
          this.form.imageKtp = URL.createObjectURL(files);
        } else {
          this.form.fileSelfie = files;
          this.form.imageSelfie = URL.createObjectURL(files);
        }

        return true;
      }
    },
    onUpdate() {
      let reasonKtp = "";
      let reasonSelfie = "";
      if (
        this.form.ktpStatus === 2 &&
        typeof this.form.reasonKtp !== "string"
      ) {
        reasonKtp = this.form.reasonKtp.id;
      }
      if (
        this.form.selfieStatus === 2 &&
        typeof this.form.reasonSelfie !== "string"
      ) {
        reasonSelfie = this.form.reasonKtp.id;
      }

      const formData = new FormData();
      formData.append("address", this.form.address);
      formData.append("birthday", this.form.birthday);
      formData.append("city_id", this.form.cityId);
      formData.append("district_id", this.form.districtId);
      formData.append("gender", this.form.gender);
      formData.append("id_number", this.form.nik);
      formData.append("ktp_reason", reasonKtp);
      formData.append("ktp_status", this.form.ktpStatus);
      formData.append("name", this.form.name);
      formData.append("postal_code", this.form.postalCode);
      formData.append("province_id", this.form.provinceId);
      formData.append("selfie_reason", reasonSelfie);
      formData.append("selfie_status", this.form.selfieStatus);
      formData.append("phone", this.data.phone);
      formData.append("village_id", this.form.villageId);
      formData.append(
        "idcard_image",
        typeof this.form.fileKtp === "object" ? this.form.fileKtp : ""
      );
      formData.append(
        "selfie_image",
        typeof this.form.fileSelfie === "object" ? this.form.fileSelfie : ""
      );

      this.isLoading = true;
      this.loadingSubmit = true;
      this.$swal
        .fire({
          title: "Apakah anda yakin ?",
          text: "Pastikan data sudah benar!",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Kembali"
        })
        .then(res => {
          if (res.value) {
            this.$http
              .post(`/partner/verify-update/${this.data.id}`, formData)
              .then(response => {
                this.isLoading = false;
                this.$swal
                  .fire({
                    title: "Success!",
                    html: `KTP berhasil Diupdate. <br/> ${
                      response.data?.meta?.message === undefined
                        ? ``
                        : response.data?.meta?.message
                    }`,
                    type: "success",
                    showCancelButton: true,
                    confirmButtonText: "Kembali Ke Menu",
                    cancelButtonText: "Tutup"
                  })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: "Verifikasi Partner" });
                    }
                    this.loadingSubmit = false;
                  });
              })
              .catch(error => {
                if (error.response) {
                  this.isLoading = false;
                  this.errors.code = error.response.status;
                  this.errors.message = error.response.data.meta.message;
                  this.errors.status = error.response.data.meta.code;
                  if (this.errors.status === 404) {
                    this.$swal
                      .fire("Failed!", "Ktp failed to updated.", "error")
                      .then(() => {
                        location.reload();
                      });
                  }

                  if (this.errors.status === 400) {
                    this.$swal.fire("Failed!", this.errors.message, "error");
                  }
                  this.loadingSubmit = false;
                }
              });
          } else {
            this.loadingSubmit = false;
          }
        });
    },
    async onFetchDetail() {
      this.isLoading = true;
      await this.$http
        .put(`partner/verify/` + atob(this.$route.params.id))
        .then(result => {
          this.isLoading = false;
          this.data = result.data.verify;
          this.form = {
            name: result.data.verify.name,
            birthday: result.data.verify.birthday
              ? moment(result.data.verify.birthday).format("DD-MM-YYYY")
              : "",
            gender: result.data.verify.gender,
            nik: result.data.verify.ktp,
            ktpStatus: result.data.verify.ktp_status,
            selfieStatus: result.data.verify.selfie_status,
            reasonKtp: result.data.verify.ktp_reason || "",
            reasonSelfie: result.data.verify.selfie_reason || "",
            imageKtp: result.data.verify.ktp_image,
            imageSelfie: result.data.verify.selfie_image,
            address: result.data.verify.address,
            district: result.data.verify.district_id,
            province: result.data.verify.province_id,
            village: result.data.verify.village_id,
            city: result.data.verify.city_id,
            postalCode: result.data.verify.postal_code,
            phone: result.data.verify.phone,
            email: result.data.verify.email
          };

          if (this.form.ktpStatus === 1) {
            this.btnKycKtp = true;
          } else {
            this.btnKycKtp = false;
          }
          if (this.form.selfieStatus === 1) {
            this.btnKycSelfie = true;
          } else {
            this.btnKycSelfie = false;
          }
        })
        .catch(error => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
          }
        });

      // Get Province
      await this.getProvince();

      // Get City
      if (this.form.province) await this.getCity(this.form.province);

      // Get District
      if (this.form.city) await this.getDistrict(this.form.city);

      // Get Village
      if (this.form.city && this.form.district)
        await this.getVillage(this.form.city, this.form.district);
    },
    async checkProvince(val, type = false) {
      if (!val || val !== this.form.province) {
        const findProvince = this.getIdValue(val, this.provinceData);

        this.form.province = val;
        this.form.provinceId = findProvince || "";
        this.form.city = null;
        this.form.cityId = "";
        this.form.district = null;
        this.form.districtId = "";
        this.form.village = null;
        this.form.villageId = "";
        this.form.postalCode = "";
        this.cityOption = [];
        this.districtOption = [];
        this.villageOption = [];

        await this.getCity(this.form.province);
      } else if (type) {
        const findProvince = this.getIdValue(val, this.provinceData);

        this.form.province = val;
        this.form.provinceId = findProvince || "";
        await this.getCity(this.form.province);
      }
    },
    async checkCity(val, type = false) {
      if (!val || val !== this.form.city) {
        const findCity = this.getIdValue(val, this.cityData);

        this.form.city = val;
        this.form.cityId = findCity || "";
        this.form.district = null;
        this.form.districtId = "";
        this.form.village = null;
        this.form.villageId = "";
        this.form.postalCode = "";
        this.districtOption = [];
        this.villageOption = [];

        if (this.form.city) await this.getDistrict(this.form.city);
      } else if (type) {
        const findCity = this.getIdValue(val, this.cityData);

        this.form.city = val;
        this.form.cityId = findCity || "";
        if (this.form.city) await this.getDistrict(this.form.city);
      }
    },
    async checkDistrict(val) {
      if (!val || val !== this.form.district) {
        const findDistrict = this.getIdValue(val, this.districtData);

        this.form.district = val;
        this.form.districtId = findDistrict || "";
        this.form.village = null;
        this.form.villageId = "";
        this.form.postalCode = "";
        this.villageOption = [];

        if (this.form.city && this.form.district)
          this.getVillage(this.form.city, this.form.district);
      }
    },
    async fillPostalCode(val) {
      if (!val || val !== this.form.village) {
        const findVillage = this.getIdValue(val, this.villageData);

        this.isLoading = true;
        this.form.village = val;
        this.form.villageId = findVillage || "";

        if (this.form.city && this.form.district && this.form.village) {
          const city = this.cityData.filter(v => {
            return v.text === this.form.city;
          });

          const district = this.districtData.filter(v => {
            return v.text === this.form.district;
          });

          await axios
            .get(
              process.env.VUE_APP_SECRET +
                `admin/v2/command/postal-code-command?city=${city[0].value}&district=${district[0].value}&village=${this.form.village}`
            )
            .then(res => {
              this.isLoading = false;
              this.form.postalCode = res.data.data;
            });
        } else {
          this.form.postalCode = "";
        }

        this.isLoading = false;
      }
    },
    async getProvince() {
      this.isLoading = true;
      await axios
        .get(process.env.VUE_APP_SECRET + "admin/v2/command/province-command")
        .then(res => {
          this.isLoading = false;
          this.provinceData = res.data.data;
          this.provinceOption = res.data.data.map(itm => {
            return itm.text;
          });
        });
    },
    async getCity(val) {
      this.isLoading = true;

      if (val) {
        const findProvince = this.provinceData.filter(v => {
          return v.text === val;
        });

        await axios
          .get(
            process.env.VUE_APP_SECRET +
              `admin/v2/command/city-command?province=${findProvince[0].value}`
          )
          .then(res => {
            this.isLoading = false;
            this.cityData = res.data.data;
            this.cityOption = res.data.data.map(itm => {
              return itm.text;
            });
          });
      } else {
        await axios
          .get(process.env.VUE_APP_SECRET + `admin/v2/command/city-command`)
          .then(res => {
            this.isLoading = false;
            this.cityData = res.data.data;
            this.cityOption = res.data.data.map(itm => {
              return itm.text;
            });
          });
      }
    },
    async getDistrict(val) {
      this.isLoading = true;

      if (val) {
        const findCity = this.cityData.filter(v => {
          return v.text === val;
        });

        await axios
          .get(
            process.env.VUE_APP_SECRET +
              `admin/v2/command/district-command?city=${findCity[0].value}`
          )
          .then(res => {
            this.isLoading = false;
            this.districtData = res.data.data;
            this.districtOption = res.data.data.map(itm => {
              return itm.text;
            });
          });
      } else {
        await axios
          .get(process.env.VUE_APP_SECRET + `admin/v2/command/district-command`)
          .then(res => {
            this.isLoading = false;
            this.districtData = res.data.data;
            this.districtOption = res.data.data.map(itm => {
              return itm.text;
            });
          });
      }
    },
    async getVillage(city, district) {
      this.isLoading = true;

      if (city && district) {
        const findDistrict = this.districtData.filter(v => {
          return v.text === district;
        });

        const findCity = this.cityData.filter(v => {
          return v.text === city;
        });

        await axios
          .get(
            process.env.VUE_APP_SECRET +
              `admin/v2/command/village-command?city=${findCity[0].value}&district=${findDistrict[0].value}`
          )
          .then(res => {
            this.isLoading = false;
            this.villageData = res.data.data;
            this.villageOption = res.data.data.map(itm => {
              return itm.text;
            });
          });
      }

      this.isLoading = false;
    },
    chkState(val) {
      const field = this.$v.form[val];
      return !field.$invalid || !field.$invalid;
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some(child => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
    updateOCR() {
      let reasonKtp = "";
      let reasonSelfie = "";
      if (
        this.form.ktpStatus === 2 &&
        typeof this.form.reasonKtp !== "string"
      ) {
        reasonKtp = this.form.reasonKtp.id;
      }
      if (
        this.form.selfieStatus === 2 &&
        typeof this.form.reasonSelfie !== "string"
      ) {
        reasonSelfie = this.form.reasonKtp.id;
      }

      const formData = new FormData();
      formData.append("address", this.form.address);
      formData.append("birthday", this.form.birthday);
      formData.append("city_id", this.form.cityId);
      formData.append("district_id", this.form.districtId);
      formData.append("gender", this.form.gender);
      formData.append("id_number", this.form.nik);
      formData.append("ktp_reason", reasonKtp);
      formData.append("ktp_status", this.form.ktpStatus);
      formData.append("name", this.form.name);
      formData.append("postal_code", this.form.postalCode);
      formData.append("province_id", this.form.provinceId);
      formData.append("selfie_reason", reasonSelfie);
      formData.append("selfie_status", this.form.selfieStatus);
      formData.append("phone", this.data.phone);
      formData.append("village_id", this.form.villageId);
      formData.append(
        "idcard_image",
        typeof this.form.fileKtp === "object" ? this.form.fileKtp : ""
      );
      formData.append(
        "selfie_image",
        typeof this.form.fileSelfie === "object" ? this.form.fileSelfie : ""
      );
      formData.append("thirdparty_update", true);

      this.$http
        .post(`/partner/verify-update/${this.data.id}`, formData)
        .then(response => {
          this.busy = false;
          this.loadingSubmit = false;
        })
        .catch(error => {
          if (error.response) {
            this.isLoading = false;
            this.errors.code = error.response.status;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
            if (this.errors.status === 404) {
              this.$swal
                .fire("Failed!", "Ktp failed to updated.", "error")
                .then(() => {
                  location.reload();
                });
            }

            if (this.errors.status === 400) {
              this.$swal.fire("Failed!", this.errors.message, "error");
            }
            this.busy = false;
            this.loadingSubmit = false;
          }
        });
    },
    async verifyKYC(value) {
      const actionsMap = {
        dukcapil: {
          title: "proses Verifikasi Dukcapil",
          url: "privy-check",
          successMethod: this.handlingVerify.bind(this)
        },
        ocr: {
          title: "proses OCR KTP",
          url: "ocr-check",
          successMethod: this.handlingOCR.bind(this)
        },
        forgery: {
          title: "proses Forgery",
          url: "id-forgery",
          successMethod: this.handlingForgery.bind(this)
        },
        faceCompare: {
          title: "Verifikasi Face Compare",
          url: "face-compare",
          successMethod: this.handlingFaceCompare.bind(this)
        }
      };
      const action = actionsMap[value];

      if (!action) return;

      this.busy = true;
      this.loadingSubmit = true;
      this.makeToast(
        "Data Sedang Diproses",
        "Mohon tunggu sampai data selesai di proses",
        "warning"
      );

      const formData = new FormData();

      let reasonKtp = "";
      let reasonSelfie = "";
      if (
        this.form.ktpStatus === 2 &&
        typeof this.form.reasonKtp !== "string"
      ) {
        reasonKtp = this.form.reasonKtp.id;
      }
      if (
        this.form.selfieStatus === 2 &&
        typeof this.form.reasonSelfie !== "string"
      ) {
        reasonSelfie = this.form.reasonSelfie.id;
      }

      formData.append("address", this.form.address);
      formData.append("birthday", this.form.birthday);
      formData.append("city_id", this.form.cityId);
      formData.append("district_id", this.form.districtId);
      formData.append("gender", this.form.gender);
      formData.append("id_number", this.form.nik);
      formData.append("ktp_reason", reasonKtp);
      formData.append("ktp_status", this.form.ktpStatus);
      formData.append("name", this.form.name);
      formData.append("postal_code", this.form.postalCode);
      formData.append("province_id", this.form.provinceId);
      formData.append("selfie_reason", reasonSelfie);
      formData.append("selfie_status", this.form.selfieStatus);
      formData.append("phone", this.data.phone);
      formData.append("village_id", this.form.villageId);
      formData.append(
        "idcard_image",
        typeof this.form.fileKtp === "object" ? this.form.fileKtp : ""
      );
      formData.append(
        "selfie_image",
        typeof this.form.fileSelfie === "object" ? this.form.fileSelfie : ""
      );

      await this.$http
        .post(`/partner/${action.url}/${this.data.id}`, formData)
        .then(res => {
          const data = res?.data;
          if (data?.meta?.code == 200) {
            this.isLoading = true;
            action.successMethod(data?.data);
          }
        })
        .catch(error => {
          const err = error?.response?.data;

          if (err) {
            this.makeToast(
              `Gagal ${action.title}`,
              err?.meta?.message,
              "danger"
            );
            this.busy = false;
            this.loadingSubmit = false;

            if (err?.meta?.code === 400) {
              if (value === "dukcapil") {
                this.form.ktpStatus = 2;
                this.form.selfieStatus = 2;
                this.form.reasonKtp = "RC-06 - Invalid Data";
                this.form.reasonSelfie = "RC-06 - Invalid Data";
              } else if (value === "ocr") {
                this.form.ktpStatus = 2;
                this.form.reasonKtp = "RC-06 - Invalid Data";
              } else if (value === "forgery") {
                this.form.ktpStatus = 2;
                this.form.reasonKtp = "RC-06 - Invalid Data";
              } else {
                this.form.ktpStatus = 2;
              }
            }
          }
        });
    },
    makeToast(title, message, variant) {
      this.$bvToast.toast(message, {
        title,
        variant,
        append: true,
        noAutoHide: false
      });
    },
    async handlingOCR(response) {
      this.makeToast(
        `Proses ${response?.title}`,
        response?.description,
        response?.is_verified ? "success" : "danger"
      );
      this.busy = false;
      this.loadingSubmit = false;

      if (!response.is_limit) {
        this.form.name = response.name;
        this.form.birthday = response.birthday
          ? moment(response.birthday).format("DD-MM-YYYY")
          : "";
        this.form.gender = response.gender === "PEREMPUAN" ? "female" : "male";
        this.form.nik = response.idcard_number;
        this.form.address =
          response.address_building !== null
            ? response.address_area + " " + response.address_building
            : response.address_area;
        this.form.province = response.issued_province;
        this.form.city = response.issued_city;
        this.form.district = response.address_districts;
        this.form.village = response.address_quarters;
        await this.checkProvince(this.form.province, true);
        await this.checkCity(this.form.city, true);
        await this.checkDistrict(this.form.district, true);
      } else {
        this.form.ktpStatus = 2;
        this.form.reasonKtp = "RC-06 - Invalid Data";
      }
    },
    async handlingVerify() {
      await this.$http
        .put(`partner/verify/` + atob(this.$route.params.id))
        .then(result => {
          this.data = result.data.verify;
          this.form.ktpStatus = result.data.verify.ktp_status;
          this.form.selfieStatus = result.data.verify.selfie_status;
          this.form.reasonKtp = result.data.verify.ktp_reason;
          this.form.reasonSelfie = result.data.verify.selfie_reason;
        })
        .catch(error => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
          }
        });
      this.busy = false;
      this.loadingSubmit = false;
    },
    handlingForgery(response) {
      this.makeToast(
        "Sukses proses Forgery",
        response.title,
        response?.is_verified ? "success" : "danger"
      );
      this.handlingVerify();
      this.busy = false;
      this.loadingSubmit = false;
    },
    handlingFaceCompare(response) {
      this.makeToast(
        "Sukses proses Face Compare",
        response.title,
        response.is_pass ? "success" : "danger"
      );
      this.handlingVerify();
      this.busy = false;
      this.loadingSubmit = false;
    }
  }
};
</script>

<style>
.submt {
  width: 100%;
  height: auto;
  display: block;
}
.error-input .dropdown-toggle {
  border-color: red !important;
}
.error-message {
  color: #fa836b;
}
</style>
<style>
.form-control {
  height: 36.22px;
}

.upload-images-kyc img {
  width: fit-content;
  height: 160px;
}

.text-red {
  color: #cb211f;
}
.text-success {
  color: #35aa4b;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
